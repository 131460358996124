import { WACampaignsService } from "../../services";
import { CAMPAIGN_CHANNEL, CAMPAIGN_STATUS, TEMPLATE_FILE_TYPES } from "../enums/campaigns.enum";
import { WACampaignsLogModel } from "./whatsapp-campaigns-log.model";

export class WACampaignsModel {
  uuid: string;
  name: string;
  message_data: {
    whatsapp_id: string;
    template: {
      created: string;
      media_type: TEMPLATE_FILE_TYPES,
      message_template: {},
      modified: string;
      name: string;
      //TODO change the typing here to be correct "MEDIA"
      type: string;
      uuid: string;
      whatsapp_number: string;
    };
    template_header_url: string;
  }
  campaign_status: CAMPAIGN_STATUS;
  total_recipients: number;
  total_recipients_subscribed: number;
  total_recipients_unsubscribed: number;
  channel_type: CAMPAIGN_CHANNEL;
  campaign_list: string;
  campaign_logs: WACampaignsLogModel[];
  campaign_list_name: string;
  created: string;
  modified: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      name: '',
      message_data: {
        whatsapp_id: '',
        template_name: {},
      },
      campaign_status: '',
      total_recipients: 0,
      total_recipients_subscribed: 0,
      total_recipients_unsubscribed: 0,
      channel_type: '',
      campaign_list: '',
      campaign_logs: [],
      campaign_list_name: '',
      created: '',
      modified: '',
      ...init,
    };

    Object.assign(this, data);
  }

  static search(query: any = {}, ...kwargs) {
    return WACampaignsService.search(query, ...kwargs);
  }
}