import moment from "moment";
import { Card } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StatisticsTile } from "../../../components";
import { DoughnutGraph, LineGraph } from "../../../components/Graphs";
import { ArrowDownCircleIcon, ArrowUpCircleIcon, InfoCircleIcon, MessageFailedIcon, MessageReceivedIcon, MessageSentIcon, PhoneInTalkIcon } from "../../../components/icons";
import { getActiveOrganization } from "../../../redux/selectors";
import { setSmsTab } from "../../../redux/actions";
import { SMS_TAB } from "../../../utils/enums";
import { DashboardFilters } from "../Filters";
import { StatsService } from "../../../services";
import { formatGraphData, formatData } from "../utils";
import { usableFilters, getFilterValue, useFilter } from "../../../utils/helpers";
import { CalendarIcon } from '../../../components/icons';
import { DateRangeFilter } from '../../../parts';

let lastFilters: any;

const categories = [
  { type: 'range', title: 'Date Range', icon: CalendarIcon, iconSize: 20, args: {withOutCustom: true}, component: DateRangeFilter },
];

export const MessagesDashboard: FC = () => {
    const organization = useSelector(getActiveOrganization);
    let { filters, setFilters } = useFilter();
    const [messagesReceived, setMessagesReceived] = React.useState<any>({
        count: 0,
        total_spam: 0,
        total_blacklisted_sender: 0,
        total_blacklisted_keyword: 0,
        total_received: 0,
        total_sent: 0,
        total_delivered: 0,
        total_undelivered: 0,
    });
    const [messagesSent, setMessagesSent] = React.useState<any>({
        count: 0,
        total_delivered: 0,
        total_undelivered: 0,
        total_sent: 0,
        total_failed: 0,
    });
    const [messagesReceivedGraph, setMessagesReceivedGraph] = React.useState<any>([]);
    const [messagesSentGraph, setMessagesSentGraph] = React.useState<any>([]);

    React.useEffect(() => {
      lastFilters = {};
    }, [organization]);

    React.useEffect(() => {
        const usable_filters = usableFilters(filters);
        const { startDate, endDate } = getFilterValue(usable_filters, "range");

        if (JSON.stringify(lastFilters) === JSON.stringify(usable_filters))
          return;
        lastFilters = usable_filters;

        StatsService.stats(
            "sms",
            {
                start_date: startDate,
                end_date: endDate,
                context: "call_tracking",
                fields: [
                    "count",
                    "total_spam",
                    "total_blacklisted_sender",
                    "total_blacklisted_keyword",
                    "total_received",
                    "total_sent",
                    "total_delivered",
                    "total_undelivered",
                ]
            }
        ).then(formatData).then(stats => {
            setMessagesReceived(stats)
        })

        StatsService.stats(
            "sms",
            {
                start_date: startDate,
                end_date: endDate,
                context: "send_sms",
                fields: [
                    "count",
                    "total_delivered",
                    "total_undelivered",
                    "total_sent",
                    "total_failed",
                ]
            }
        ).then(formatData).then(stats => {
            setMessagesSent(stats)
        })

        StatsService.graph(
            "sms",
            {
                start_date: startDate,
                end_date: endDate,
                context: "call_tracking",
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setMessagesReceivedGraph(stats)
        })

        StatsService.graph(
            "sms",
            {
                start_date: startDate,
                end_date: endDate,
                context: "send_sms",
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setMessagesSentGraph(stats)
        })
    }, [filters, organization]);

    return (
        <div className="p-4 grid grid-cols-12 gap-x-8">
            <div className="col-span-12">
                <DashboardFilters
                    filters={filters}
                    onChange={setFilters}
                    categories={categories}/>
            </div>
            <h1 className="text-lg font-bold col-span-6 m-4">Messages Received</h1>
            <h1 className="text-lg font-bold col-span-6 m-4">Messages Sent</h1>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 col-span-6">
                <StatisticsTile
                    //TODO match icons and colors to designs
                    value={messagesReceived.count.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Received"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#5ca7a0] bg-opacity-16"
                    //tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <MessageReceivedIcon
                        size={25}
                        color={'#5ca7a0'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={messagesReceived.total_spam.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Spam Blocked"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-danger bg-opacity-16"
                    //tooltipIcon={<ArrowUpCircleIcon size={15} color={'#ec6666'} />}
                >
                    <InfoCircleIcon
                        size={30}
                        color={'danger'}
                    />
                </StatisticsTile>
            </div>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 col-span-6">
                <StatisticsTile
                    value={messagesSent.count.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Sent"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#4457ff] bg-opacity-16"
                    //tooltipIcon={<ArrowDownCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <MessageSentIcon
                        size={25}
                        color={'#4457ff'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={messagesSent.total_failed.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Failed"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#eaaa4d] bg-opacity-16"
                >
                    <MessageFailedIcon
                        size={25}
                        color={'#eaaa4d'}
                    />
                </StatisticsTile>
            </div>
            <div className="col-span-6">
                <Card className="px-4 pb-4 mb-4 relative h-100">
                    <LineGraph
                        title="Messages Received"
                        labels={messagesReceivedGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Messages Received',
                                data: messagesReceivedGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 relative h-100">
                    <DoughnutGraph
                        title="Messages Received Status"
                        data={[
                            {
                                label: "Blacklisted Keyword",
                                value: messagesReceived.total_blacklisted_keyword,
                            },
                            {
                                label: "Blacklisted Sender",
                                value: messagesReceived.total_blacklisted_sender,
                            },
                            {
                                label: "Received",
                                value: messagesReceived.total_received,
                            },
                            {
                                label: "Sent",
                                value: messagesReceived.total_sent,
                            },
                            {
                                label: "Delivered",
                                value: messagesReceived.total_delivered,
                            },
                            {
                                label: "Undelivered",
                                value: messagesReceived.total_undelivered,
                            },
                        ]}
                    />
                </Card>
            </div>
            <div className="col-span-6">
                <Card className="px-4 pb-4 mb-4 relative h-100">
                    <LineGraph
                        title="Messages Sent"
                        labels={messagesSentGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Messages Sent',
                                data: messagesSentGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 relative h-100">
                    <DoughnutGraph
                        title="Messages Sent Status"
                        data={[
                            {
                                label: "Delivered",
                                value: messagesSent.total_delivered,
                            },
                            {
                                label: "Undelivered",
                                value: messagesSent.total_undelivered,
                            },
                            {
                                label: "Sent",
                                value: messagesSent.total_sent,
                            },
                        ]}
                    /></Card>
            </div>
        </div>
    )
}
