import { Card } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StatisticsTile } from "../../../components";
import { DoughnutGraph, LineGraph } from "../../../components/Graphs";
import { ArrowUpCircleIcon, CoinsIcon, InfoCircleIcon, PhoneCallUnansweredIcon, PhoneInTalkIcon, PhoneUniqueCallerIcon, TimeIntervalIcon } from "../../../components/icons";
import { setCallTab } from "../../../redux/actions";
import { getActiveOrganization } from "../../../redux/selectors";
import { CALL_TAB } from "../../../utils/enums";
import { DashboardFilters } from "../Filters";
import { StatsService } from "../../../services";
import { formatGraphData, formatData } from "../utils";
import { usableFilters, getFilterValue, useFilter } from "../../../utils/helpers";
import {
  CalendarIcon,
  HashTagIcon,
} from '../../../components/icons';
import { DateRangeFilter, NumberFilter } from '../../../parts';

let lastFilters: any;

const categories = [
  { type: 'number', title: 'Sudo Number', icon: HashTagIcon, iconSize: 20, args: {}, component: NumberFilter },
  { type: 'range', title: 'Date Range', icon: CalendarIcon, iconSize: 20, args: {withOutCustom: true}, component: DateRangeFilter },
];

export const CallsDashboard: FC = () => {
    const organization = useSelector(getActiveOrganization);
    let { filters, setFilters } = useFilter();
    const [callsReceived, setCallsReceived] = React.useState<any>({
        count: 0,
        avg_duration: 0,
        unique_calls: 0,
        total_answered: 0,
        total_not_answered: 0,
        total_spam: 0,
        total_missed_call: 0,
        total_missed_call_network_failure: 0,
        total_busy: 0,
        total_reject: 0,
    });
    const [callsMade, setCallsMade] = React.useState<any>({
        count: 0,
        avg_duration: 0,
        unique_calls: 0,
        total_cost: 0,
        total_caller_number_busy: 0,
        total_caller_number_missed_call: 0,
        total_caller_number_missed_call_network_failure: 0,
        total_caller_number_reject: 0,
        total_answered: 0,
        total_not_answered: 0,
        total_missed_call: 0,
        total_missed_call_network_failure: 0,
        total_busy: 0,
        total_reject: 0,
    });
    const [callsReceivedGraph, setCallsReceivedGraph] = React.useState<any[]>([]);
    const [callsMadeGraph, setCallsMadeGraph] = React.useState<any[]>([]);

    React.useEffect(() => {
      lastFilters = {};
    }, [organization]);

    React.useEffect(() => {
        const usable_filters = usableFilters(filters);
        const number = getFilterValue(usable_filters, "number")?.replace(/\D/g, '');;
        const { startDate, endDate } = getFilterValue(usable_filters, "range");

        if (JSON.stringify(lastFilters) === JSON.stringify(usable_filters))
          return;
        lastFilters = usable_filters;

        StatsService.stats(
            "calls",
            {
                number,
                start_date: startDate,
                end_date: endDate,
                context: "call-tracking",
                fields: [
                    "count", "avg_duration", "unique_calls",
                    "total_answered",
                    "total_not_answered",
                    "total_spam",
                    "total_missed_call",
                    "total_missed_call_network_failure",
                    "total_busy",
                    "total_reject",
                ]
            }
        ).then(formatData).then(stats => {
            setCallsReceived(stats)
        })

        StatsService.stats(
            "calls",
            {
                number,
                start_date: startDate,
                end_date: endDate,
                context: "voice-call",
                fields: [
                    "count", "avg_duration", "unique_calls", "total_cost",
                    "total_caller_number_busy",
                    "total_caller_number_missed_call",
                    "total_caller_number_missed_call_network_failure",
                    "total_caller_number_reject",
                    "total_answered",
                    "total_not_answered",
                    "total_missed_call",
                    "total_missed_call_network_failure",
                    "total_busy",
                    "total_reject",
                ]
            }
        ).then(formatData).then(stats => {
            setCallsMade(stats)
        })

        StatsService.graph(
            "calls",
            {
                number,
                start_date: startDate,
                end_date: endDate,
                context: "call-tracking",
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setCallsReceivedGraph(stats)
        })

        StatsService.graph(
            "calls",
            {
                number,
                start_date: startDate,
                end_date: endDate,
                context: "voice-call",
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setCallsMadeGraph(stats)
        })
    }, [organization, filters]);

    return (
        <div className="p-4 grid grid-cols-12 gap-x-8">
            <div className="col-span-12">
                <DashboardFilters
                    filters={filters}
                    onChange={setFilters}
                    categories={categories} />
            </div>
            <h1 className="text-lg font-bold col-span-6 m-4">Calls Received</h1>
            <h1 className="text-lg font-bold col-span-6 m-4">Calls Made</h1>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 col-span-6">
                <StatisticsTile
                    value={callsReceived.count.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Total Calls"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-blue bg-opacity-16"
                >
                    <PhoneInTalkIcon
                        size={25}
                        color={'blue'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsReceived.total_answered.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Answered"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-success bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <PhoneInTalkIcon
                        size={25}
                        color={'success'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsReceived.total_not_answered.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Unanswered"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#4856F6] bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#ec6666'} />}
                >
                    <PhoneCallUnansweredIcon
                        size={25}
                        color={'#4856f6'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsReceived.avg_duration.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Avg Duration (sec)"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-primary bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <TimeIntervalIcon
                        size={25}
                        color={'primary'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsReceived.unique_calls.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Unique Callers"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#5ca7a0] bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <PhoneUniqueCallerIcon
                        size={25}
                        color={'#5ca7a0'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsReceived.total_spam.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Spam"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-danger bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#ec6666'} />}
                >
                    <InfoCircleIcon
                        size={30}
                        color={'danger'}
                    />
                </StatisticsTile>
            </div>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 col-span-6">
                <StatisticsTile
                    value={callsMade.count.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Total Calls"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-blue bg-opacity-16"
                >
                    <PhoneInTalkIcon
                        size={25}
                        color={'blue'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsMade.total_answered.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Answered"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-success bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <PhoneInTalkIcon
                        size={25}
                        color={'success'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsMade.total_not_answered.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Unanswered"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#4856F6] bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#ec6666'} />}
                >
                    <PhoneCallUnansweredIcon
                        size={25}
                        color={'#4856f6'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsMade.avg_duration.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Avg Duration (sec)"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-primary bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <TimeIntervalIcon
                        size={25}
                        color={'primary'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsMade.unique_calls.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label="Unique Callers"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#5ca7a0] bg-opacity-16"
                    // tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />}
                >
                    <PhoneUniqueCallerIcon
                        size={25}
                        color={'#5ca7a0'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={callsMade.total_cost.toLocaleString()}
                    color="#0B1C34"
                    valueClass="text-3xl"
                    label={(organization?.billing_currency) ? `Cost (${organization.billing_currency})`: "Cost"}
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-primary bg-opacity-16"
                >
                    <CoinsIcon
                        size={25}
                        color={'primary'}
                    />
                </StatisticsTile>
            </div>
            <div className="col-span-6">
                <Card className="px-4 pb-4 mb-4 relative h-100">
                    <LineGraph
                        title="Calls Received"
                        labels={callsReceivedGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Calls Received',
                                data: callsReceivedGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 relative h-100">
                    <DoughnutGraph
                        title="Calls Received Status"
                        data={[
                            {
                                label: "Answered",
                                value: callsReceived.total_answered,
                            },
                            {
                                label: "Missed Call",
                                value: callsReceived.total_missed_call,
                            },
                            {
                                label: "Missed Call Network Failure",
                                value: callsReceived.total_missed_call_network_failure,
                            },
                            {
                                label: "Busy",
                                value: callsReceived.total_busy,
                            },
                            {
                                label: "Spam",
                                value: callsReceived.total_spam,
                            },
                            {
                                label: "Rejected",
                                value: callsReceived.total_reject,
                            },
                        ]}
                    />
                </Card>
            </div>
            <div className="col-span-6">
                <Card className="px-4 pb-4 mb-4 relative h-100">
                    <LineGraph
                        title="Calls Made"
                        labels={callsMadeGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Calls Made',
                                data: callsMadeGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 relative h-100">
                    <DoughnutGraph
                        title="Calls Made Status"
                        data={[
                            {
                                label: "Answered",
                                value: callsMade.total_answered,
                            },
                            {
                                label: "Busy",
                                value: callsMade.total_busy,
                            },
                            {
                                label: "Missed Call",
                                value: callsMade.total_missed_call,
                            },
                            {
                                label: "Missed Call Network Failure",
                                value: callsMade.total_missed_call_network_failure,
                            },
                            {
                                label: "Caller Number Busy",
                                value: callsMade.total_caller_number_busy,
                            },
                            {
                                label: "Caller Number Missed Call",
                                value: callsMade.total_caller_number_missed_call,
                            },
                            {
                                label: "Caller Number Missed Call Network Failure",
                                value: callsMade.total_caller_number_missed_call_network_failure,
                            },
                            {
                                label: "Caller Number Rejected",
                                value: callsMade.total_caller_number_reject,
                            },
                        ]}
                    />
                </Card>
            </div>
        </div>
    )
}
