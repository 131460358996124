import moment from "moment";

function isInt(n) {
    return n % 1 === 0;
}

export const formatGraphData = graph => {
    var format = "D MMM";

    if (graph.period === "hour") format = "H:00"

    for (const obj of graph.results) {
        const m = moment(obj.time);
        obj.time = m.format(format)
    }
    return graph.results
}

export const formatData = stats => {
    if (stats.total_cost)
        stats.total_cost = parseFloat(stats.total_cost)

    if (stats.avg_duration)
        stats.avg_duration = stats.avg_duration.toFixed(0)

    for (const k in stats) {
        if (stats[k] === null)
            stats[k] = 0

        if (!isInt(stats[k]))
            stats[k] = stats[k].toFixed(2)
    }
    return stats
}
