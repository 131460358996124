import Tooltip from '@mui/material/Tooltip';
import classnames from "classnames";
import React, { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataTable, IDataTableColumn, TruncatedTableCell } from "../../../../components";
import { EditIcon, InfoCircleIcon } from "../../../../components/icons";
import { getActiveOrganization } from "../../../../redux/selectors";
import { WACampaignsService } from "../../../../services";
import { ISearchCampaignRequest } from '../../../../utils/dto';
import { CAMPAIGN_STATUS } from "../../../../utils/enums/campaigns.enum";
import { formatDateTime, formatPhoneNumber } from "../../../../utils/helpers";
import { WACampaignsModel } from "../../../../utils/types";

export interface ICampaignTableProps {
    firstCreate?: (result?: any) => void;
    className?: string;
    filter: any;
    onEdit?: (result?: any) => void;
    onView?: (result?: any) => void;
    setTableRef: any;
}

const EmptyColumn = ({ className }: any | null) => {
    return (
        <div className={classnames(className, '!font-bold')}>-</div>
    )
}

export const CampaignsTable: FC<ICampaignTableProps> = ({
    firstCreate,
    className,
    filter,
    onEdit,
    onView,
    setTableRef
}) => {
    const organization = useSelector(getActiveOrganization);

    const columns = useMemo<IDataTableColumn<WACampaignsModel>[]>(() => {
        return [
            {
                title: 'Name',
                field: 'name',
                tooltip: { title: 'Campaign Name' },
                render(row: WACampaignsModel) {
                    return <TruncatedTableCell cellData={row.name} cellClass='!max-w-[80px]' />
                }
            },
            {
                title: 'WABA Sent From',
                field: 'message_data.whatsapp_id',
                tooltip: { title: 'WABA Sent From' },
                render(row: WACampaignsModel) {
                    return row.message_data.whatsapp_id ? <Link to={`/numbers/${row.message_data.whatsapp_id}`}>
                        <div className="text-primary text-11p !font-bold">{formatPhoneNumber(row.message_data.whatsapp_id)}</div>
                    </Link> : <EmptyColumn className='text-primary text-center' />
                }
            },
            {
                title: 'Status',
                field: 'campaign_sent_status',
                tooltip: { title: 'Status' },
                cellClass: '!pr-6',
                headerClass: '!pr-6',
                render(row: WACampaignsModel) {
                    return <span
                        className={classnames("p-2 rounded-md",
                            { 'text-[#79D7EE] !font-semibold bg-[#E2F8FD]': row.campaign_status === CAMPAIGN_STATUS.DRAFT },
                            { 'text-[#2680EB] !font-semibold bg-[#D9E6F7]': row.campaign_status === CAMPAIGN_STATUS.RUNNING },
                            { 'text-[#7ED93D] !font-semibold bg-[#DFEDD5]': row.campaign_status === CAMPAIGN_STATUS.COMPLETED },
                        )}
                    >{row.campaign_status}</span>;
                }
            },
            {
                title: 'Lists',
                field: 'campaign_list_name',
                tooltip: { title: 'Campaign List' },
                render(row: WACampaignsModel) {
                    return (
                        <>
                            {row?.campaign_list_name ?
                                <TruncatedTableCell cellData={row.campaign_list_name} cellClass='!max-w-[240px]' />
                                :
                                <EmptyColumn className='text-center' />
                            }
                        </>
                    )
                }
            },
            {
                title: 'Total Sent',
                field: 'campaign_logs.contact_count',
                tooltip: { title: 'Total Sent' },
                render(row: WACampaignsModel) {
                    return row.campaign_logs[0]?.contact_count.toString() || <EmptyColumn />
                }
            },
            {
                title: 'Failed',
                field: 'campaign_logs.messages_failed_count',
                tooltip: { title: 'Failed' },
                render(row: WACampaignsModel) {
                    return row.campaign_logs[0]?.messages_failed_count.toString() || <EmptyColumn />
                }
            },
            {
                title: 'Opt Out',
                field: 'campaign_logs.opted_out_count',
                tooltip: { title: 'Opted Out' },
                render(row: WACampaignsModel) {
                    return row.campaign_logs[0]?.opted_out_count.toString() || <EmptyColumn />
                }
            },
            {
                title: 'Date Sent',
                field: 'modified',
                tooltip: { title: 'Date Sent' },
                render(row: WACampaignsModel) {
                    return formatDateTime(row.modified)
                },
            },
            {
                cellClass: '!sticky !right-0 !bg-white',
                headerClass: '!sticky !right-0',
                render(row: WACampaignsModel) {
                    const isDraft = row.campaign_status === CAMPAIGN_STATUS.DRAFT;
                    return (
                        <div className="flex items-center justify-end">
                            {
                                isDraft ?
                                    (
                                        <Tooltip title="Edit Campaign" arrow placement="top">
                                            <div
                                                className={"w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"}
                                                onClick={() => onEdit(row)}
                                            >
                                                <EditIcon color="blue" size={22} />
                                            </div>
                                        </Tooltip>
                                    )
                                    :
                                    (
                                        <Tooltip title="View Details" arrow placement="top">
                                            <div
                                                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                                                onClick={() => onView(row)}
                                            >
                                                <InfoCircleIcon color="blue" size={18} />
                                            </div>
                                        </Tooltip>
                                    )
                            }
                        </div>
                    );
                },
            },
        ];
    }, [filter, organization]);

    const loadCampaigns = useCallback((options, callback) => {
        const query: ISearchCampaignRequest = {
            page: options.page + 1,
            page_size: options.perPage,
        }
        if (filter.search) {
            query.name = filter.search
        }

        WACampaignsService.search(query, false)
            .then((data) => {
                firstCreate(filter.search.length === 0 && data.results.length === 0)
                callback({
                    count: data.count,
                    data: data.results,
                });
            }).catch(() => {
                callback({
                    count: 0,
                    data: [],
                });
            });
    }, [organization, filter]);

    return (
        <DataTable
            wrapperClass={className}
            columns={columns}
            serverSide
            datasource={loadCampaigns}
            size="xs"
            stripped
            stickyHeader
            rowsPerPage={50}
            pagination
            selfPagination
            headerCellClass="!text-13p"
            cellClass="!text-11p"
            onInit={setTableRef}
        />
    );
};
