import { Card } from "@mui/material";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatisticsTile } from "../../../components";
import { DoughnutGraph, LineGraph } from "../../../components/Graphs";
import { ArrowDownCircleIcon, ArrowUpCircleIcon, CoinsIcon, InfoCircleIcon, MessageBubblesIcon, PeopleIcon, PersonCheckIcon, PhoneInIcon, PhoneInTalkIcon, PhoneUniqueCallerIcon, TimeIntervalIcon, WhatsAppIcon } from "../../../components/icons";
import { getActiveOrganization } from "../../../redux/selectors";
import { usableFilters, getFilterValue, useFilter } from "../../../utils/helpers";
import { StatsService } from "../../../services";
import { formatGraphData, formatData } from "../utils";
import { DashboardFilters } from "../Filters";
import { CalendarIcon } from '../../../components/icons';
import { DateRangeFilter } from '../../../parts';

let lastFilters: any;

const categories = [
  { type: 'range', title: 'Date Range', icon: CalendarIcon, iconSize: 20, args: {withOutCustom: true}, component: DateRangeFilter },
];

export const WhatsappLeadsDashboard: FC = () => {
    const organization = useSelector(getActiveOrganization);
    let { filters, setFilters } = useFilter();
    const [messagesStats, setMessagesStats] = React.useState<any>({ });
    const [leadStats, setLeadStats] = React.useState<any>({});
    const [avgResponseWorkingHours, setAvgResponseWorkingHours] = React.useState<number>(0);
    const [whatsappMessagesSentGraph, setWhatsappMessagesSentGraph] = React.useState<any>([]);
    const [whatsappMessagesReceivedGraph, setWhatsappMessagesReceivedGraph] = React.useState<any>([]);
    const [leadsGraph, setLeadsGraph] = React.useState<any>([]);

    React.useEffect(() => {
      lastFilters = {};
    }, [organization]);

    React.useEffect(() => {
        const usable_filters = usableFilters(filters);
        const { startDate, endDate } = getFilterValue(usable_filters, "range");

        if (JSON.stringify(lastFilters) === JSON.stringify(usable_filters))
          return;
        lastFilters = usable_filters;

        StatsService.stats(
            "whatsapp-messages",
            {
                start_date: startDate,
                end_date: endDate,
                fields: ["count"],
            }
        ).then(stats => formatData(stats)).then(stats => {
            setMessagesStats(stats)
        })

        StatsService.stats(
            "chatintrolog",
            {
                start_date: startDate,
                end_date: endDate,
                created_timeinterval: ["09:00:00", "18:00:00"],
                fields: ["avg_lead_response_time"],
                status: ["BPARTY_CONFIRMED", "BPARTY_CONFIRMED_CSAT"]
            }
        ).then(stats => formatData(stats)).then(stats => {
            setAvgResponseWorkingHours(stats.avg_lead_response_time)
        })

        StatsService.stats(
            "chatintrolog",
            {
                start_date: startDate,
                end_date: endDate,
                fields: [
                    "total_leads", "total_clicked", "total_lead_responded_to",
                    "avg_lead_response_time",
                    "total_aparty_message_arrived",
                    "total_aparty_message_received", "total_bparty_notified",
                    "total_bparty_confirmed",
                    "total_aparty_notification", "total_aparty_qualifying",
                    "total_bparty_confirmed_csat"
                ],
            }
        ).then(stats => formatData(stats)).then(stats => {
            setLeadStats(stats)
        })

        StatsService.graph(
            "chatintrolog",
            {
                start_date: startDate,
                end_date: endDate,
                status_excl: ["APARTY_BLOCKED", "READY", "APARTY_CHAT_LINK_CLICKED"],
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setLeadsGraph(stats)
        })

        StatsService.graph(
            "whatsapp-messages",
            {
                start_date: startDate,
                end_date: endDate,
                direction: "OUTBOUND",
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setWhatsappMessagesSentGraph(stats)
        })

        StatsService.graph(
            "whatsapp-messages",
            {
                start_date: startDate,
                end_date: endDate,
                direction: "INBOUND",
            }
        ).then(stats => formatGraphData(stats)).then(stats => {
            setWhatsappMessagesReceivedGraph(stats)
        })
    }, [filters, organization]);

    return (
        <div className="p-4 grid grid-cols-12 ">
            <div className="col-span-12">
                <DashboardFilters
                    filters={filters}
                    onChange={setFilters}
                    categories={categories}/>
            </div>
            <h1 className="text-lg font-bold col-span-6 m-4">Whatsapp Lead Funnel</h1>
            <h1 className="text-lg font-bold col-span-6 m-4">Response Times</h1>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 col-span-6">
                <StatisticsTile
                    value={leadStats.total_clicked}
                    valueClass="text-3xl"
                    color="#0B1C34"
                    label="Chat Links Clicked"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#4BCA5A] bg-opacity-16"
                >
                    <WhatsAppIcon
                        size={30}
                        color={'#4BCA5A'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={leadStats.total_leads}
                    valueClass="text-3xl"
                    color="#0B1C34"
                    label="Leads"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#5ca7a0] bg-opacity-16"
                    /* tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />} */
                >
                    <PeopleIcon
                        size={25}
                        color={'#5ca7a0'}
                    />
                </StatisticsTile>
                <StatisticsTile
                    value={leadStats.total_lead_responded_to}
                    valueClass="text-3xl"
                    color="#0B1C34"
                    label="Leads Responded to"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-[#4457FF] bg-opacity-16"
                    /* tooltipIcon={<ArrowUpCircleIcon size={15} color={'#cbe54e'} />} */
                >
                    <PersonCheckIcon
                        size={25}
                        color={'#4457FF'}
                    />
                </StatisticsTile>
            </div>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 col-span-6">
                <StatisticsTile
                    value={avgResponseWorkingHours}
                    valueClass="text-3xl"
                    color="#0B1C34"
                    label="9am-6pm (Sec)"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-blue bg-opacity-16"
                    /* tooltipIcon={<ArrowUpCircleIcon size={15} color={'#ec6666'} />} */
                />
                <StatisticsTile
                    value={leadStats.avg_lead_response_time}
                    valueClass="text-3xl"
                    color="#0B1C34"
                    label="Anytime (Sec)"
                    labelClass="text-sm"
                    labelPlacement="top"
                    backdropClass="bg-success bg-opacity-16"
                    /* tooltipIcon={<ArrowDownCircleIcon size={15} color={'#cbe54e'} />} */
                />
                <StatisticsTile
                    value={messagesStats.count}
                    valueClass="text-3xl"
                    color="#0B1C34"
                    label="WhatsApp Messages"
                    labelClass="text-sm"
                    className="2xl:!mr-0"
                    labelPlacement="top"
                    backdropClass="bg-[#307cd8] bg-opacity-16"
                >
                    <MessageBubblesIcon
                        size={25}
                        color={'#307cd8'}
                    />
                </StatisticsTile>
            </div>
            <div className="grid grid-cols-12 gap-x-4 col-span-12">
                <Card className="px-4 pb-4 mb-4 relative h-100 col-span-7">
                    <LineGraph
                        title="WhatsApp Leads per day"
                        labels={leadsGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Leads',
                                data: leadsGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 mb-4 relative h-100 col-span-5">
                    <DoughnutGraph
                        title="WhatsApp Lead Status"
                        data={[
                            {
                                label: "Ready",
                                value: leadStats.total_ready,
                            },
                            {
                                label: "Aparty Chat Link Clicked",
                                value: leadStats.total_aparty_chat_link_clicked,
                            },
                            {
                                label: "Aparty Message Arrived",
                                value: leadStats.total_aparty_message_arrived,
                            },
                            {
                                label: "Aparty Message Received",
                                value: leadStats.total_aparty_message_received,
                            },
                            {
                                label: "Bparty Notified",
                                value: leadStats.total_bparty_notified,
                            },
                            {
                                label: "Bparty Confirmed",
                                value: leadStats.total_bparty_confirmed,
                            },
                            {
                                label: "Aparty Blocked",
                                value: leadStats.total_aparty_blocked,
                            },
                            {
                                label: "Aparty Notification",
                                value: leadStats.total_aparty_notification,
                            },
                            {
                                label: "Aparty Qualifying",
                                value: leadStats.total_aparty_qualifying,
                            },
                            {
                                label: "Bparty Confirmed Csat",
                                value: leadStats.total_bparty_confirmed_csat,
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 mb-4 relative h-100 col-span-6">
                    <LineGraph
                        title="WhatsApp Messages Sent"
                        labels={whatsappMessagesSentGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Sent',
                                data: whatsappMessagesSentGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
                <Card className="px-4 pb-4 mb-4 relative h-100 col-span-6">
                    <LineGraph
                        title="WhatsApp Messages Received"
                        labels={whatsappMessagesReceivedGraph.map(x => x.time)}
                        data={[
                            {
                                color: '#307CD8',
                                label: 'Received',
                                data: whatsappMessagesReceivedGraph.map(x => x.count),
                            },
                        ]}
                    />
                </Card>
            </div>
        </div>
    )
}
